<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список лендингов ({{ landings.length }})</h2>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="updateList()"
        >Обновить список</b-button
        >
      </div>
    </div>
    <div style="margin-top: 60px;">
      <v-expansion-panels multiple v-for="item in landings" :key="item.id">
        <v-expansion-panel>
          <v-expansion-panel-header class="table-filters-header"
          >{{ item.name }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-data-table :headers="headers"
                          :items="item.pages" hide-default-footer></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>

export default {
  name: "LandingsList",
  components: {
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("GET_LANDINGS");
  },
  computed: {
    landings() {
      return this.$store.getters.LANDINGS;
    },
    headers() {
      return [
        { text: 'Id', value: 'id' },
        { text: 'Название', value: 'name' },
        { text: 'Ссылка', value: 'url' },
      ];
    },
  },
  methods: {
    updateList() {
      this.$store.dispatch("UPDATE_LANDINGS_LIST")
        .then(() => {
          this.$toast.success("Список лендингов обновлен");
          this.$store.dispatch("GET_LANDINGS");
        })
        .catch(() => {
          this.$toast.error("Неизвестная ошибка!");
        });
    },
  },
};
</script>

<style>
.table-filters-header {
  font-size: 20px;
  font-weight: 500;
}
</style>
